.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
   
    color: #fff;
    
  }
  
  .navbar-brand {
    font-size: 24px;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
  }
  
  .navbar-links a,
  .dropdown-toggle {
    margin: 0 10px;
    color: #7ab7fd;
    text-decoration: none;
    cursor: pointer;
  }
  
  .dropdown {
    position: relative;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    color: #333;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .dropdown-menu a {
    color: #333;
    text-decoration: none;
    padding: 8px 12px;
    white-space: nowrap;
  }
  
  .dropdown-menu a:hover {
    background-color: #ddd;
  }
  
  /* Responsive Styles */
  @media (max-width: 700px) {
    .navbar-links {
      display: none;
    }
  
    .navbar.active .navbar-links {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .dropdown-menu {
      position: static;
    }
  }
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #ffffff00;
   
  }
  
  .navbar-brand {
    font-size: 24px;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
  }
  
  .pages,
  .dropdown-toggle {
    margin: 0 10px;
    color: #7ab7fd;
    text-decoration: none;
    cursor: pointer;
    background-color: none;
    display: flex;
    justify-content:center;
    align-items: center;
    user-select: nonew;
    
  }
  .navbar-links{
    cursor: pointer;
    font-size: 1.1rem;

  }
  .pages{
    color: #7ab7fd;
    position: relative;
    display: inline-block;
    text-decoration: none;
  }
  .pages:hover{
    color: #47caf1;
    
    border-radius: 5px;
    transition: 0.4s ease-in-out;
    
    
  }
  .pages::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 100%;
    height: 1px;
    background: #51eef369;
    border-radius: 40px;
    transform: scale(0);
    transition: transform 0.4s ease-in-out;
  }
  .other-link::after{
    content: '';
    position: absolute;
    left: 0;
    padding-left: 2rem;
    bottom: -6px;
    width: 40%;
    height: 1px;
    background: #f3515169;
    border-radius: 0px;
    transform: scale(0);
    transition: transform 0.4s ease-in-out;
  }
  .pages:hover::after{
    transform-origin: left;
    transform: scale(1);
    transition: transform 0.4s ease-in-out;
  }
  .other-link:hover::after{
    transform-origin: left;
    transform: scale(1);
    transition: transform 0.4s ease-in-out;
  }
  
  .dropdown {
    position: relative;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    color: #333;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 111;
  }
  
  .dropdown-menu a {
    color: #333;
    text-decoration: none;
    padding: 8px 12px;
    white-space: nowrap;
  }
  
  .dropdown-menu a:hover {
    background-color: #ddd;
  }
  #contact
  {
    background-color: #FA2D2D;
    padding: 6px 1rem;
    border-radius: 5px;
    font-weight: 400;
    cursor: pointer;
    transition: 0.4s ease-in-out;

  }
  #contact:hover{
    background-color: #5ea1f8;
    color: #fff;
    transition: 0.4s ease-in-out;
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  #atreya-logo{
    width: 60px;
  }
  .contacts-button{
    background-color: #93e3fc;
  }
  /* Responsive Styles */
  @media (max-width: 700px) {
    .navbar-links {
      display: none;
    }
  
    .navbar.active .navbar-links {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .dropdown-menu {
      position: static;
    }
  }
  
  /*Dropdown css*/
  .dropdown-menu{
    border-radius: 6px !important;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    width: 100%;
    display: flex;
    text-align: left;
    cursor: pointer;

  }
  .dropdown-links{
    padding: 5px;
    color: #837f7f;
    transition: 0.3s ease-in-out;
    font-size: 1rem;
  }
  .dropdown-links:hover{
    background-color: #f35151;
    color: white;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    font-size: 1.1rem;
  }

  /*Hamburger*/
  #hamburger{
    color: black;
    font-size: 3rem;
    display: none;
    cursor: pointer;
  }
  .hamburger-menu{
    display: none;
  }
  @media (min-width:100px) and (max-width:700px) {
    #hamburger{
        display: flex;
    }
    .hamburger-menu{
        display: flex;
    }
   
}
@media (max-width: 700px) {
    .contacts-button {
      display: none;
    }
  }
.link{
  text-decoration: none;
  color: white;
}
  .vertical-navbar {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: #000000;
    color: rgb(253, 86, 86);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 1000;
    transition: transform 0.1s ease-in-out;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);
  }
  
  .vertical-navbar-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 24px;
  }
  
  .vertical-navbar-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .navbar-brands{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .logo-name{
    color: #47caf1;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .quote{
    color: #41c1e7;
  }