@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

section {
  display: flex;
  flex-direction: column;
  max-width: 90rem;
  width: 85%;
  margin: 0 auto;
  align-content: center;
  overflow: hidden;
}

h1 {
  text-transform: capitalize;
  font-size: 2.4rem;
  margin-bottom: 2rem;
  font-weight: 700;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;

  @media (min-width: 61.25rem) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    cursor: pointer;
    padding: 2em;
    border-radius: 1rem;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2.188rem;
    align-items: center;
    transition: 0.3s;
    position: relative;
    border: 0.094rem solid transparent;

    img {
      display: block;
      width: 3.35rem;
      height: 3.35rem;
      border-radius: 50%;
      filter: grayscale(1);
      transition: 0.5s;
    }

    > div {
      h3 {
        text-transform: capitalize;
        font-size: 1.025rem;
      }

      p {
        text-transform: capitalize;
        color: #767676;
        font-size: 0.9rem;
      }
    }

    .gradient {
      background-image: linear-gradient(
        to right,
        #4755690a,
        #9d0cb28a,
        #4343c899,
        #4755690a
      );
      width: 50%;
      height: 0.094rem;
      position: absolute;
      content: "";
      bottom: -0.063rem;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0px 0.125rem 0.75rem #4343c84d;
    }
  }

  .card.active {
    background: #fff;
    border: 0.094rem solid #0f172a14;

    .gradient {
      display: block;
    }

    img {
      filter: grayscale(0);
    }
  }
}

.content {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: 61.25rem) {
    margin-top: 0;
    flex-direction: row;
  }

  .contentBox {
    
    height: auto;
    padding: 1rem;
    background-color: #f3f3f3;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    transition: 0.3s;

    @media (min-width: 61.25rem) {
      width: 60%;
      padding: 2rem;
    }

    .text {
      h2 {
        margin-bottom: 1rem;
        font-size: 1.25rem;
      }

      p {
        font-size: 0.95rem;
        line-height: 1.6;
      }

      .stars {
        display: flex;
        gap: 0.25rem;
        margin-top: 0.5rem;

        svg {
          width: 1rem;
          height: 1rem;
          fill: #ffd700;
        }
      }
    }
  }

  .contentBox.active {
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}

body {
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}
