/* Companies.css */
.company-container {
    background-color: rgb(253, 253, 253);
    color: white;
    
    display: flex;
    flex-direction: column;
    align-items: center;
   
    position: relative; /* Needed for positioning arrows */
    overflow: hidden;
}

.company-top {
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 100%;
}

.custom-arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    flex-direction: column;
}
.arrows{
    padding-top: 3rem;
}
.desc-lines {
    height: 2px;
    background: linear-gradient(98.3deg, rgb(255, 255, 255) 10.6%, rgb(255, 0, 0) 97.7%);
    width: 200px;
    margin: 0 auto;
    margin-bottom: 1rem;
}


.company-bottom {
    width: 100%;
  
    
    overflow: hidden;
}
.company-line{
    border-bottom: 1px solid rgb(255, 255, 255);
    border-top: 1px solid rgb(255, 255, 255);
    
   
}
.logo-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.logo-item img {
    
    max-height: 150px;
    object-fit: contain;
}

/* CustomArrows.css */
.custom-arrow {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 24px;
}

.custom-left-arrow, .custom-right-arrow {
    
    top: 50%;
    transform: translateY(-50%);
}

.desc-titles{
    text-align: center;
    font-size: 35px;
}
.logo-item img{
    width: 240px;
}