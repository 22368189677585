.student-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    margin-top: 5rem;
    margin-bottom: 5rem;
   
   
  }
  
  .student-title {
    color: rgb(39, 143, 228);
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .student-content {
    display: flex;
    border-radius: 1rem;
    align-items: center;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    max-height: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .student-review {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  .std-name{
    padding-top: 5px;
  }
  .student-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .student-text {
    max-width: 80%;
  }
  
  .student-text h3 {
    margin: 0 0 10px 0;
    color: #333;
  }
  
  .student-text h4 {
    margin: 0 0 10px 0;
    color: #666;
  }
  
  .student-text p {
    color: #555;
    margin: 5px 0;
  }
  