.course-container{
     /* background-color: rgb(122, 183, 253);*/
}
.course-content{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    flex-wrap: wrap;
}
.serv{
    
    width: 200px;
    height: 450px;
    z-index: -1;
}
.courses{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: fit-content;
    border-radius: 10px;
    padding-bottom:1rem ;
    box-shadow: 2px 2px 2px 2px rgba(95, 95, 95, 0.096);
    transition: 0.3s ease-in-out;
    margin-top: 2rem;
    cursor: pointer;
}
.courses:hover{
    transform: scale(1.05);
    transition: 0.3s ease-in-out;
}

.course-title{
    text-align: center;

}
.course-title-top{
    font-size: 3rem;
    font-weight: 400;
    color: rgb(0, 0, 0);
    padding: 0px;
    margin: 0px;
    z-index: 2;

}
.course-duration{
    
    text-align: center;
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.932);

}
.course-description{
    text-align: center;
}
.course-img{
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
}
.course-img img{
    width: 280px;
    height: 200px;
    border-radius: 10px;
}
.more{
    display: flex;
    justify-content: center;
    align-items: center;
   
    background-color: rgb(15, 119, 255);
    width: fit-content;
    margin: 0 auto;
    padding: 10px 10px;
    border-radius: 6px;
    font-size: 1rem;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}
.course-low-description{
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
}
