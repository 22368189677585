.footer-container{
    display: flex;
    color: rgba(255, 255, 255, 0.425);
    flex-direction: column;
  
    background-color: rgba(0, 0, 0, 0.877);
    padding: 2rem;
    overflow: hidden;
    cursor: pointer;
    
}
.footer-top{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}





.footer-bottom{
    margin-top: 3rem;
    padding-top: 2rem;
    margin: 0 auto;
}

.footer-socials{
    margin-top: 1.2rem;
    display: flex;
    gap: 5px;
}
.footer-socials a{
    text-decoration: none;
}
.icon{
    color: white;
    font-size: 2rem;
}















.footer-title{
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
}


.footer-img img{
    width: 250px;
}


@media (min-width: 0px) and (max-width: 376px) {
   
   .footer-top{
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
    align-items: center;
   }
   .footer-bottom{
    text-align: center;
   }
}

@media (min-width: 376px) and (max-width: 600px) {
    .footer-img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .footer-img img{
        width: 100px;
    }
    .footer-top{
        flex-direction: row;
        flex-wrap: wrap;
        gap: 3rem;
        justify-content: center;
        align-items: center;
       }
       .footer-bottom{
        text-align: center;
       }
}

@media (min-width: 600px) and (max-width: 900px) {
    .footer-img img{
        width: 100px;
    }
}

@media (min-width: 900px) and (max-width: 1100px) {
    
}
