.hero-container {
    background-color: #f8f8f8;
    height: fit-content;
    padding: 1rem;
    display: flex;
    justify-content: space-evenly;
    
}

.hero-left {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
}

.hero-title {
    font-size: 45px;
    color: rgb(0, 0, 0);
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.text-color {
    color: rgb(39, 143, 228);
}

.title-next-line {
    font-size: 53px;
    color: rgb(97, 170, 253);
    padding: 0;
    margin: 0;
}
.blob{
    position: absolute;
    width: 100px;
    

}
.blob1{
    
    z-index: 0
    ;

}
.hero-text {
    font-size: 18px;
    color: rgba(73, 73, 73, 0.637);
    padding-top: 2rem;
}

.hero-button {
    margin-top: 2rem;
    font-size: 20px;
    padding: 1rem 2rem;
    border-radius: 5px;
    background-color: rgb(58, 177, 224);
    color: white;
    cursor: pointer;
    transition: 0.4s ease-in-out;
}

.hero-button:hover {
    background-color: rgb(0, 126, 175);
    transition: 0.4s ease-in-out;
}
.hero-right{
    padding-top: 1rem;
}

.hero-left {
    min-width: 350px;
}
.fade-in {
    opacity: 1; /* Show the image with fade-in effect */
  }
  .hero-image img {
    border-radius: 1rem;
  }
/* Media queries for different screen sizes */
@media (min-width: 0px) and (max-width: 376px) {
    .hero-container {
        padding-top: 3rem;
        display: flex;
        height: fit-content;
        flex-direction: column;
        justify-content: space-evenly;
        justify-content: center;
        align-items: center;
    }

    .hero-left {
        padding-top: 1rem;
        padding: 1rem;
    }

    .hero-image img {
        overflow: hidden;
        max-width: 350px;
    }
}

@media (min-width: 376px) and (max-width: 600px) {
    .hero-container {
        padding-top: 3rem;
        display: flex;
        height: fit-content;
        flex-direction: column;
        justify-content: space-evenly;
        justify-content: center;
        align-items: center;
    }

    .hero-left {
        padding-top: 1rem;
        padding: 1rem;
    }

    .hero-image img {
        overflow: hidden;
        max-width: 390px;
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    .hero-container {
        padding-top: 3rem;
        display: flex;
        height: fit-content;
        flex-direction: column;
        justify-content: space-evenly;
        justify-content: center;
        align-items: center;
    }

    .hero-left {
        padding-top: 1rem;
        padding: 1rem;
    }

    .hero-image img {
        overflow: hidden;
        max-width: 600px;
    }
}

@media (min-width: 900px) and (max-width: 1100px) {
    .hero-container {
        padding-top: 3rem;
        display: flex;
        height: fit-content;
        justify-content: space-evenly;
        justify-content: center;
        align-items: center;
        padding-bottom: 3rem;
    }

    .hero-left {
        padding-top: 1rem;
        padding: 1rem;
    }

    .hero-image img {
        overflow: hidden;
        max-width: 400px;
    }
}
