.contact-container{
    overflow: hidden;
    padding-bottom: 2rem;
}
.contact{
    display: flex;
    flex-direction: row;
    width: 100%;
    
    justify-content: center;
    padding: 1rem;
    
    
    
}
.copy{
    color: rgb(61, 161, 255);
    background-color: transparent;
    font-size: 1rem;
}
.copy:hover{
    color: rgb(15, 78, 136);
    background-color: transparent;
}
.left-contact{
    display: flex;
    flex-direction:column;
    
    height: 100%;
    justify-content:space-evenly;
    padding: 2rem;
}
.right-contact{
    display: flex;
    flex-direction:column;
    
    justify-content: center;
    align-items: center;
}
.top-right form{
    display: flex;
    padding: 2rem;
    gap: 2rem;
    flex-direction: column;
}
.inner{
    display: flex;
    flex-direction: column;
}
.inner input{
    padding-top: 5px;
    width: 500px;
    
    border: 1px solid rgba(128, 128, 128, 0.681);
    background: rgba(248, 244, 244, 0.652);
    text-align: left;
    padding: 1rem;
    border-radius: 15px;
}
.send-message{
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 140px;
    border: none;
    background: #1976d2;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    padding: 0.7rem;
    cursor: pointer;
    border-radius: 10px;
}
.inner span{
    color: gray;
}
input ::placeholder{
    color: gray;
}
#Message{
    height: 140px;
    padding-top: 5px;
    width: 500px;
    
    border: 1px solid rgba(128, 128, 128, 0.681);
    background: rgba(248, 244, 244, 0.652);
    text-align: left;
    padding: 1rem;
    border-radius: 15px;
}
#Message ::placeholder{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.top-left{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    
}
.top-left h1,h2{
    font-size: 54px;
}
.bottom-left{
    display: flex;
}
.top-left span{
    padding-top: 5px;
    
    color: #1976d2;
}
.nomargin{
    margin: 0px;
}
.bottom-left{
   
    display: flex;
    border: 2px black;
    flex-direction: row;
    border-radius: 15px;
}

.contact-info{
    display: flex;
    border: 2px solid #1976d2;
    border-radius: 20px;
    padding: 1rem;
    flex-direction: column;
    min-width: 355px;
}
.lower{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.lower-container{
    display: flex;
    width: 80%;
    border-radius: 15px;
    height: 400px;
    background-color: rgb(207 218 230);
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: 1px solid rgba(157, 149, 149, 0.922);
}
.left-text{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 30px;
}
.left-text span{
    color: orange;
    font-weight: 700;
    padding: 1rem;
    text-decoration:wavy;
    font-size: 3rem;
}
.right-image{
    display: flex;
    height: 100%;
}
.right-image img{
    width: 250px;
    height: 350px;
    position: relative;
    top: 12%;
}
.google-map{
    border-radius: 20px;
    width: 90%;
    margin: 0 auto;
}
.google-map iframe{
    border-radius: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.459);
}
.send-message:hover{
    
    background: #1976d2ad;
}
.contact-title{
    color: rgb(27 27 27);
    
    font-size: 50px;
    padding-top: 2rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    display: flex;
}
@media (min-width:1000px) and (max-width:1200px)
{
    .top-left h1,h2{
        font-size: 40px;
    }
    .contact-info{
        min-width: 300px;
        width: 300px;
    }
    .left-contact{
        justify-content: center;
    }

}
@media (min-width:550px) and (max-width:1000px)
{
    .contact{
        display: flex;
        flex-direction: column;
        width: 100%;
        
        justify-content: center;
        padding: 1rem;
        
    }
    .left-contact{
        align-items: center;
    }
    .left-text p{
        font-size: 30px;

    }
    .left-text span{
        font-size: 40px;
    }
    .right-image img{
        width: 200px;
        height: 350px;
        top: 12%;
        position: relative;
            
    }
    .lower-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        border-radius: 1px;
        justify-content: space-evenly;
        align-items: center;

    }
    .google-map{
        width: 100%;
        border-radius: 1px;
    }
    .google-map iframe{
        border-radius: 1px;
    }
    .right-image{
        display: flex;
        height: 100%;

    }
}
@media (min-width:0px) and (max-width:550px)
{
    .copy{
        font-size: 17px;
    }
    .contact{
        display: flex;
        flex-direction: column;
        width: 100%;
        
        justify-content: center;
        padding: 1rem;
        
    }
    .left-contact{
        align-items: center;
    }
    .left-text p{
        font-size: 15px;
        padding: 5px;

    }
    .left-text span{
        font-size: 25px;
        padding: 5px;
    }
    .right-image{
        display: flex;
        height: 100%;

    }
    .right-image img{
        width: 200px;
        height: 350px;
        top: 12%;
        position: relative;
        
    }
    .lower-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        border-radius: 1px;
        justify-content: space-evenly;
        align-items: center;

    }
    
    .google-map{
        width: 100%;
        border-radius: 0px;
    }
    .google-map iframe{
        border-radius: 1px;
    }
    #Message{
        height: 140px;
        padding-top: 5px;
        width: 300px;
        
        border: 1px solid rgba(128, 128, 128, 0.681);
        background: rgba(248, 244, 244, 0.652);
        text-align: left;
        padding: 1rem;
        border-radius: 15px;
    }
    .inner input{
        padding-top: 5px;
        width: 300px;
        height: 45px;
        border: 1px solid rgba(128, 128, 128, 0.681);
        background: rgba(248, 244, 244, 0.652);
        text-align: left;
        padding: 1rem;
        border-radius: 15px;
    }
    .contact-info{
        min-width: 270px;
        width: 270px;
    }
    
}