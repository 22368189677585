.gallery {
    overflow: hidden;
  }
  
  .gallery-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .gallery-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    height: 300px; /* Set a fixed height for the slide */
  }
  #gs{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    height: 300px; /* Set a fixed height for the slide */
  }
  
  .gallery-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Ensure images cover the container without distortion */
    border-radius: 10px;
  }
  
  @media (max-width: 1200px) {
    .gallery-container {
      width: 90%;
    }
 
  }
  
  @media (max-width: 600px) {
    .gallery-container {
      width: 90%;
    }
    .gallery-image {
        max-width: 350px;
        max-height: 500px;
        
        border-radius: 10px;
      }
  }

/*News css*/
.news-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem;
    gap: 20px;
    
  }
  
  .news-article {
    display: flex;
    width: 100%;
    max-width: 600px;
    background-color: #f8f9fa;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .news-image {
    width: 40%;
    
    object-fit: cover;
  }
  
  .news-details {
    padding: 20px;
    width: 60%;
    display: flex;
    flex-direction: column;
  }
  
  .news-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .news-snippet {
    flex-grow: 1;
    margin-bottom: 15px;
  }
  
  .read-more {
    align-self: flex-end;
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .read-more:hover {
    color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .news-article {
      flex-direction: column;
      width: 90%;
    }
  
    .news-image {
      width: 100%;
      max-height: 300px;
    }
  
    .news-details {
      width: 90%;
    }
  }
  
  .read{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(25, 95, 245);
    color: white;
    padding: 1rem 2rem;
    border-radius: 5px;
    
    border: none;
    margin: 0 auto;
    cursor: pointer;
    transition: 0.4s ease-in-out;
  }
  .read:hover{
    background-color:#61a0f3 ;
    color: white;
    transition: 0.4s ease-in-out;
    
  }
  .title{
    text-align: center;
    font-size: 3rem;
    color: #ffffff;
  }
  .sub-title{
    text-align: center;
    font-size: 1rem;
    color: #ffffffaf;
  }
  .new{
    background-color: rgb(122, 183, 253);
    padding: 3rem 0rem;
   
  }
  /*Courses*/
  .course-carousel {
    margin: 0px auto;
    padding: 2rem;
   width: 80%;
    overflow: hidden;
    border-radius: 1rem;
   
  }
  .gap{
    box-shadow: 0px 9px 5px #aaaaaa;
    border-radius: 1rem;
  }
  .course-image {
    border-radius: 1rem;
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 10px 10px 5px #aaaaaa;
    
  }
  
  .course-details {
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 0 0 8px 8px;
  }
  
  .course-title {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .course-description {
    font-size: 0.9rem;
    color: #666;
  }
  
  .more-courses {
    margin: 0 auto;
    text-align: center;
    margin-top: 10px;
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    background-color: #007bff;
    width: fit-content;
    color: white;
    padding: 5px 20px;
    border-radius: 1rem;
    transition: 0.4s ease-in-out;
    cursor: pointer;
  }
  .gap
  {
    margin: 1rem;
  }
  
  .more-courses:hover {
    background-color: #0063cc;
    transition: 0.4s ease-in-out;
  }
  .courses-title{
    text-align: center;
    font-size: 2rem;
    padding-top: 2rem;
   
    color: rgb(39, 143, 228);
  }

  .courses-title {
    text-align: center;
    font-size: 2rem;
    margin: 2rem 0;
    font-weight: bold;
    color: #333;
  }
  
  .course-carousel {
    position: relative;
  }
  
  .course-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px; /* Add horizontal padding to create space between items */
  }
  
  .course-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .course-details {
    padding: 1rem;
    text-align: center;
  }
  
  .course-title {
    font-size: 1.2rem;
    margin: 0.5rem 0;
    color: #333;
  }
  
  .course-description {
    font-size: 1rem;
    color: #555;
  }
  
  .more-courses {
    text-align: center;
    margin: 2rem 0;
    font-size: 1rem;
    font-weight: bold;
    color: #007bff;
    cursor: pointer;
  }
  
  .more-courses:hover {
    text-decoration: underline;
  }
  
  /* Add styling for carousel-item to control spacing */
  .carousel-item {
    margin: 0 10px; /* Add horizontal gap between carousel items */
  }
  