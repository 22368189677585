

::-webkit-scrollbar {
    width: 0.5rem;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: #797979;
    transition: all 0.5s ease-in-out;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #222224;
  }
  
  ::-webkit-scrollbar-track {
    background: #d6d4d4;
  }
  

  
  .container {
    max-width: 124rem;
    padding: 4rem 1rem;
    margin: 0 auto;
  }
  
  .heading {
    padding: 1rem 0;
    font-size: 3.5rem;
    text-align: center;
  }
  
  .swiper_container {
    height: 34rem;
    width: 80%;
    padding: 2rem 0;
    position: relative;
  }
  
  .swiper-slide {
    width: 10rem;
    height: 30rem;
    position: relative;
  }
  
  @media (max-width: 500px) {
    .swiper_container {
      height: 34rem;
    }
    .swiper-slide {
      width: 28rem !important;
      height: 34rem !important;
    }
    .swiper-slide img {
      width: 28rem !important;
      height: 34rem !important;
    }
    .swiper_container {
      height: 34rem;
      width: 100%;
      padding: 2rem 0;
      position: relative;
    }
  }
  
  .swiper-slide img {
    width: 96%;
    height: 30rem;
    border-radius: 2rem;
    object-fit: cover;
    align-items: center;
  }
  
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }
  
  .slider-controler {
  
    margin: 0 auto;
  }
  
  .slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
  }
  
  @media (max-width: 990px) {
    .slider-controler .swiper-button-next {
      left: 70% !important;
      transform: translateX(-70%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .slider-controler .swiper-button-next {
      left: 80% !important;
      transform: translateX(-80%) !important;
    }
  }
  
  @media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
      left: 30% !important;
      transform: translateX(-30%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
      left: 20% !important;
      transform: translateX(-20%) !important;
    }
  }
  
  .slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    display: none;
    border-radius: 50%;
    left: 88%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  .slider-controler .slider-arrow ion-icon {
    font-size: 5rem;
    color: #222224;
  }
  
  .slider-controler .slider-arrow::after {
    content: '';
  }
  
  .swiper-pagination {
      /* position: absolute; */
      text-align: center;
      
      transition: 300ms opacity;
      transform: translate3d(0, 0, 0);
      z-index: 10;}
  .swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.993));
  }
  
  .swiper-pagination .swiper-pagination-bullet-active {
   background-color: greenyellow;
  }
  .swiper-wrapper{
    
      transition-duration: 0ms;
      cursor: grab;
      transform:translate3d(-2786px, 0px, 0px);
      transition-delay: 0ms;
  
  }
  img{
    border-radius: 15px;
  }
  .heading{
    color: rgb(0, 0, 0);
      font-size: 3rem;
      padding-top: 10px;
      padding-bottom: 5px;
      margin: 0;
    
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 1rem;
  }
  