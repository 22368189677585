*{
  font-family: "Abel", sans-serif;
}
/* Base styles for the custom scrollbar */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Hide the scrollbar arrows */
::-webkit-scrollbar-button {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  ::-webkit-scrollbar-track {
    background: #333;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

@media (prefers-color-scheme: dark) {
  * {
    scrollbar-color: #888 #333;
  }
}

/* Specific styles for dropdown menu */
.dropdown-menu {
  overflow-y: auto; /* Enable scrolling */
  max-height: 200px; /* Adjust this to control the height of the dropdown */
}

.dropdown-menu::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.dropdown-menu::-webkit-scrollbar-button {
  display: none; /* Hide the scrollbar arrows */
}

.dropdown-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Dark mode styles for dropdown menu */
@media (prefers-color-scheme: dark) {
  .dropdown-menu::-webkit-scrollbar-track {
    background: #333;
  }

  .dropdown-menu::-webkit-scrollbar-thumb {
    background: #888;
  }

  .dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* For Firefox */
.dropdown-menu {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

@media (prefers-color-scheme: dark) {
  .dropdown-menu {
    scrollbar-color: #888 #333;
  }
}

/* Additional navbar styles */
#contact {
  background-color: #FA2D2D;
  padding: 6px 1rem;
  border-radius: 5px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

#contact:hover {
  background-color: #FA2D2D;
  color: #fff;
  transition: 0.4s ease-in-out;
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#atreya-logo {
  width: 60px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .navbar.active .navbar-links {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .dropdown-menu {
    position: static;
  }
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.hamburger .line {
  width: 100%;
  height: 2px;
  background-color: #333;
  transition: transform 0.3s ease;
}

.hamburger.open .line:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger.open .line:nth-child(3) {
  transform: translateY(-6px) rotate(-45deg);
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }
}
