.services-home-container {
    display: flex;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: rgb(122, 183, 253);
    padding: 3rem 0rem;
    min-height: 60vh;
  }
  .white{
    background-color: white;
  }
  .title-course{
    font-size: 3rem;
    font-weight: 400;
    color: rgb(255, 255, 255);
    padding: 0px;
    margin: 0px;
    z-index: 2;
  }
  .c-desc{
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.932);
    z-index: 2;
  }
  .image{
    width: 300px;
  }
  .description{
    margin: 0px;
  }
  .services-home-img img {
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    max-height: 300px;
    height: 200px;
    min-height: 200px;
}
  .top-container1 {
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    align-content: center;
}
  .services-title-container{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buttons{
    background-color: rgb(25, 95, 245);
    width: fit-content;
    margin: 0 auto;
    border-radius: 6px;
    padding: 15px 20px;
    margin-bottom: 1rem;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }
  .services-home-content {
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.295);
    background-color: rgb(255, 255, 255);
    min-height: 500px;
    max-height: 700px;
    overflow: hidden;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px; /* Added margin to separate the items */
    cursor: pointer;
    transition: 0.4s ease-in-out;
    z-index: 1;
  }
  .services-home-content:hover{
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.295);
    transform: scale(1.05);
    transition: 0.4s ease-in-out;
  }
  .course-home-heading{
    text-align: center;
  }
  
  .services-home-img img {
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  
  .texting {
    padding: 1rem;
    max-height: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6; /* Adjust the number of lines to show before the ellipsis */
    -webkit-box-orient: vertical;
  }
  
  .course-title {
    font-weight: 600;
    margin: 5px 10px 10px; /* Added some margin to create space */
    line-height: 1.4; /* Added line height for better readability */
    max-height: 50px; /* Fixed height to ensure symmetry */
    overflow: hidden; /* Hide overflowed text */
    text-overflow: ellipsis; /* Show ellipsis if text overflows */
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  .top-container {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    align-items: center;
    cursor: pointer;
  }
  
  .veri {
    font-size: 1.3rem;
    color: rgb(0, 110, 255);
  }
  
  .more-button {
    background-color: rgb(27, 144, 199);
    width: fit-content;
    border-radius: 7px;
    margin: 10px auto;
    padding: 10px 20px;
    color: white;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 2rem;
  }
  
  .more-button:hover {
    background-color: rgb(23, 126, 172);
  }
  
  /* Modal Styles */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 300px;
    max-height: 80%;
    overflow: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .close-button {
    background-color: rgb(27, 144, 199);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .close-button:hover {
    background-color: rgb(23, 126, 172);
  }
  

  .main-container {
    padding: 2rem;
    background-color: rgb(122, 183, 253);
    position: relative;
    overflow: hidden; /* Ensure blobs stay within the container */
  }
  
  .blob {
    position: absolute;
    border-radius: 50%;
    opacity: 0.7;
    pointer-events: none; /* Prevent interaction with blobs */
  }
  
  .blob1 {
    width: 200px;
    height: 200px;
    top: 10%;
    position: absolute;
    left: 10%;
    animation: moveBlob 10s ease-in-out infinite;
    
  }
  
  .blob2 {
    width: 250px;
    height: 250px;
    top: 50%;
    left: 60%;
    animation: moveBlob 12s ease-in-out infinite;
    z-index: -1;
    position: absolute;
  }
  
  .blob3 {
    width: 180px;
    position: absolute;
    height: 180px;
    top: 70%;
    left: 20%;
    animation: moveBlob 8s ease-in-out infinite;
    z-index: -1;
  }
  .blob4{
    position: absolute;
    z-index: 0;
    width: 300px;

  }
 .blob5{
    position: absolute;
    z-index: 0;
    width: 300px;
 }


  @keyframes moveBlob {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(20px, 20px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  .course-home-heading{
    position: relative;
    z-index: 100000;

  }
  .search-bar-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .search-bar {
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
   
    font-size: 16px;
  }
  .search-container{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 2rem;
    background-color: rgb(255, 255, 255);
  }
  .search-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    color: white;
  }
  .banner-container{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .banner-container img{
    width: 80%;
  }