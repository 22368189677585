.info-container{
    padding-top: 4rem;
    padding-bottom: 1rem;
}
.info-estd{
    font-size: 2rem;
    text-align: center;
    color: rgb(0, 184, 240);
}
.info-year{
text-align: center;
color: rgb(0, 202, 233);
}
.companies-work{
    text-align: center;
    color: rgba(0, 184, 240, 0.877);
}