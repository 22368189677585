.service{
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.service-container{
    overflow: hidden;
}
.about{
    margin-bottom: 5rem;
}
.about-title {
    text-align: center;
    padding-bottom: 1rem;
    padding-top: 3rem;
    font-size: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
.about-top-img{
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
}
.about-top-img img{
    width: 80%;
    height: 50%;
    
}
.our-story{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding-bottom: 2rem;
    padding: 1rem;
}
.our-storys{
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: center;
    gap: 2rem;
    padding-bottom: 2rem;
    padding: 1rem;
    overflow: hidden;
}
.our-story-right {

}
.our-story-right img{
    width: 600px;
}
#circle img{
   
}
.links{
    text-decoration: none;
    color: white;
}
.company-history {
    width: 550px;
    font-size: 17px;
    
}
.limited {
    max-height: 260px;
    overflow: hidden;
    position: relative;
}

.limited:after {
    content: '...';
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;
    padding-left: 5px;
}
.company-history p{
    padding: 0rem 1rem;
}
.our-story-title{
    color: #7ab7fd;
    font-size: 1rem;
    font-weight: 700;
    padding: 0rem 1rem;
}
.our-story-subtitle{
    font-size: 2.4rem;
    padding: 1rem;
}
.about-card{
 display: flex;
 justify-content: center;
 align-items: center;
 width: 70%;
 margin: 0 auto;
 margin-bottom: 2rem;
 
}
.about-card-container {
    background: linear-gradient(135deg, #7ab7fd, #285a92, #7ab7fd);
    padding: 20px; /* Add padding if needed */
    border-radius: 10px; /* Add border radius for rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for better visual effect */
    color: #fff; /* Set text color to white for better contrast */
  }
  .action-button{
    background-color: #5799e6;
    width: fit-content;
    margin-top: 1rem;
    padding: 10px 30px;
    font-size: 1.2rem;
    border-radius: 6px;
    margin: 0 auto;
    cursor: pointer;
  }
  
.top-text-about{
    color: white;
    font-size: 3rem;
    text-align: center;
}
.top-subtext-about{
    font-size: 1rem;
    color: rgb(223, 223, 223);
   
    text-align: center;
    padding-bottom: 1rem;
}
.read-button{
    background-color: #285a92;
    color: white;
    width: fit-content;
    border-radius: 7px;
    padding: 10px 20px;
    margin-left: 1rem;
    margin-top: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.read-button:hover{
    background-color: #5799e6;
    transition: 0.3s ease-in-out;
}


@media (min-width:400px) and (max-width:600px){
    .company-history{
        width: auto;
        font-size: 17px;
        padding: 1rem;
    }
    .our-story-right img {
        width: 400px;
    }
    .our-story-subtitle {
        font-size: 1.7rem;
        padding: 1rem;
        font-weight: 700;
    }
    .about-title{
        font-size: 3rem;
    }
    .about-card{
        width: 90%;
    }
}

@media (min-width:000px) and (max-width:400px){
    .company-history{
        width: auto;
        font-size: 17px;
        padding: 1rem;
    }
    .our-story-right img {
        width: 300px;
    }
    .our-story-subtitle {
        font-size: 1.7rem;
        padding: 1rem;
        font-weight: 700;
    }
    .about-title{
        font-size: 2rem;
    }
    .about-card{
        width: 90%;
    }
}